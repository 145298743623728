import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const Image = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: {
						eq: "bc-plangrid/file-management/image/file-management.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1300) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.fileName.childImageSharp.fluid}
			alt="More effective and efficient file management"
		/>
	)
}

export default Image
