// @flow
import * as React from 'react'

import Layout from 'components/layout'
import {
	SectionSpacer,
	ContentWrapper,
	GrayBackground,
	SectionBreak,
} from 'views/components'
import Header from './header'
import FileManagement from './file-management'
import Quote from './quote'
import Streamlining from './streamlining'

import useScreenWidth from 'hooks/use-screen-width'

const PlangridIntegration = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="PlanGrid Integration | BuildingConnected"
			description="More effective and efficient construction file management. Seamlessly push files from preconstruction to the field to save time and reduce errors."
		>
			<Header screenWidth={screenWidth} />
			<ContentWrapper>
				<SectionSpacer />
				<FileManagement />
				<SectionBreak />
				<Quote />
				<SectionSpacer />
			</ContentWrapper>
			<GrayBackground>
				<ContentWrapper>
					<SectionSpacer />
					<Streamlining />
					<SectionSpacer />
				</ContentWrapper>
			</GrayBackground>
			<SectionSpacer />
		</Layout>
	)
}

export default PlangridIntegration
