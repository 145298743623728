// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { InfoAndImage, H2 } from 'views/components'
import Image from './image'

const titleAndInfoItems = [
	{
		title: 'Reduce time',
		wording: `Don’t create the same project twice. After you create a project in BuildingConnected, all related files can be transferred over to PlanGrid.`,
	},
	{
		title: 'Minimize mistakes',
		wording: `Reduce the risk of forgetting critical files during hand-off from preconstruction and site planning.`,
	},
	{
		title: 'Stay in control',
		wording: `Gain confidence that you’ll never lose project details again when you create your PlanGrid project in BuildingConnected.`,
	},
]

const FileManagement = () => (
	<Div>
		<H2 textAlign="center">More effective and efficient file management.</H2>
		<Div height="60px" />
		<InfoAndImage
			image={<Image />}
			titleAndInfoItems={titleAndInfoItems}
			verticallyCentered
			spacerWidths={['50px', '25px']}
		/>
	</Div>
)

export default FileManagement
