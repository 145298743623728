// @flow
import * as React from 'react'

import { LargeQuote } from 'views/components'
import image from './img/image.jpg'
import image2x from './img/image@2x.jpg'
import image3x from './img/image@3x.jpg'

const Quote = () => (
	<LargeQuote
		image1x={image}
		image2x={image2x}
		image3x={image3x}
		quote="The BuildingConnected and PlanGrid integration closes the gap between the preconstruction and building processes, enabling deeper collaboration between our office and field teams."
		name="Mike Mehrwin, CRB"
	/>
)

export default Quote
