import * as React from 'react'
import { Div, A } from 'glamorous'

import Logo from './logo.svg'
import { H2, TitleAndInfoItems } from 'views/components'
import { showRequestDemoModal } from 'components/modal-manager'
import { lightBlue } from 'shared/colors'
import { laptop, tablet } from 'shared/media-queries'

const GetADemo = ({ href }) => (
	<A
		color={lightBlue}
		fontSize="18px"
		paddingTop="10px"
		onClick={() => !href && showRequestDemoModal()}
		href={href}
		target="_blank"
		cursor="pointer"
	>
		Get a demo &raquo;
	</A>
)

const Streamlining = () => (
	<Div>
		<Div textAlign="center">
			<Logo />
			<Div height="30px" />
			<H2
				css={{
					[laptop]: {
						maxWidth: '700px',
						margin: '0 auto',
					},
				}}
			>
				BuildingConnected and PlanGrid — streamlining construction.
			</H2>
		</Div>
		<Div height="50px" />
		<Div
			display="flex"
			justifyContent="space-between"
			css={{
				[tablet]: {
					flexDirection: 'column',
				},
			}}
		>
			<Div>
				<TitleAndInfoItems
					items={[
						{
							title: 'What is BuildingConnected?',
							wording: `BuildingConnected combines the largest real-time construction network with an easy-to-use platform that streamlines the preconstruction process for general contractors and subcontractors.`,
						},
					]}
				/>
				<Div height="10px" />
				<GetADemo />
			</Div>
			<Div flex="0 0 50px" />
			<Div>
				<TitleAndInfoItems
					items={[
						{
							title: 'What is PlanGrid?',
							wording: `PlanGrid boosts productivity in the field with real-time access to plans, documents, photos, punch lists and more. Share updates, track issues and look for answers from the most current set of project information.`,
						},
					]}
				/>
				<Div height="10px" />
				<GetADemo href="https://try.plangrid.com/bc-plangrid-demo/" />
			</Div>
		</Div>
	</Div>
)

export default Streamlining
