// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import Image from './image'
import { breakpoints, tablet, mobile } from 'shared/media-queries'
import { lightBlue } from 'shared/colors'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '520px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '548px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '360px'
	}
	return '580px'
}

const heading = (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		BuildingConnected + PlanGrid
	</H1>
)

const subHeading = (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		Seamlessly push files from preconstruction to the field to save time and
		reduce errors.
	</Div>
)

const Header = ({ screenWidth }: { screenWidth: number }) => (
	<Hero
		backgroundImage={
			<Div float="right">
				<Image screenWidth={screenWidth} />
			</Div>
		}
		heading={heading}
		subHeading={subHeading}
		wordingWidth={getWordingWidth(screenWidth)}
		demoButtonFullWidth={screenWidth <= breakpoints.mobile}
		leftAlign
		introducing
		ctaColor={lightBlue}
	/>
)

export default Header
